import React from "react"
// import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/UI/Layout"
import Navbar from "../components/UI/Navbar"
// import ContactPage from "../components/ContactPage"
import ContactPageTwo from "../components/ContactPageTwo"
import ContactForm from "../components/ContactForm"
import Seo from "../components/Seo"
// import NewsletterSignup from "../components/NewsletterSignup"

import { makeStyles } from "@material-ui/core/styles"
// import { makeStyles, useTheme } from "@material-ui/core/styles"
// import useMediaQuery from "@material-ui/core/useMediaQuery"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"

import headerImg from "../assets/images/03-Andreatta_Waterscapes_Pondless_Waterfall_Ashland_Oregon.jpg"

const Background = styled.div`
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url(${headerImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 0% 0%;
  color: white;
  padding: 7rem 0;
  text-align: center;
  @media only screen and (max-width: 1000px) {
    background-position: 50% 50%;
  }
  @media only screen and (max-width: 600px) {
    background-attachment: scroll;
  }
  @supports (-webkit-touch-callout: none) {
    background-attachment: scroll;
  }
`

const useStyles = makeStyles(theme => ({
  h1: {
    fontFamily: "Work Sans, sans-serif",
    fontSize: "2.5rem",
    fontWeight: 400,
    lineHeight: "1.2",
    letterSpacing: 3,
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  subtitle: {
    fontFamily: "Work Sans, sans-serif",
  },
  headerBox: {
    padding: "1.25rem",
    background: `rgba(0,0,0,0.5)`,
    color: "#fff",
    marginTop: "3rem",
    marginBottom: "2rem",
    borderRadius: 0,
    maxWidth: "60%",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
  h2: {
    fontSize: "2rem",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.75rem",
    },
  },
  h3: {
    fontSize: "1.5rem",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.33rem",
    },
  },
  h4: {
    fontSize: "1.4rem",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.15rem",
    },
  },
  li: {
    marginBottom: ".25rem",
    fontSize: "1.05rem",
  },
  h5: {
    fontSize: "1.125rem",
    fontWeight: 600,
    paddingTop: "1rem",
  },
  h6: {
    fontSize: "1rem",
    fontWeight: 400,
    marginLeft: "auto",
    marginRight: "auto",
    wordBreak: "break-all",
    textDecoration: "none",
    color: theme.palette.secondary.main,
  },
  hr: {
    marginTop: ".25rem",
    marginBottom: "1.75rem",
    maxWidth: "65%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  hrTwo: {
    backgroundColor: 'transparent',
    // marginTop: "-.25rem",
    marginTop: '3rem',
    marginBottom: "6.75rem",
    maxWidth: "65%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  contactInfoSpacer: {
    paddingTop: props => props.paddingTop,
  },
}))

let ContactPg = ({ data }) => {
  // const theme = useTheme()
  // const matchesSM = useMediaQuery(theme.breakpoints.down("sm"))
  const classes = useStyles()
  // let {
  //   streetAddress,
  //   legalAddress,
  //   cityStateZip,
  //   email,
  //   phone,
  // } = data.site.siteMetadata

  return (
    <Layout>
      <Navbar />
      <Seo
        title="Contact Us"
        description="Contact Andreatta Waterscapes for questions about building, repairing and maintaining your pond, waterfall, hardscape, bubbling rock or other water feature."
      />
      <Background>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10}>
            <Paper className={classes.headerBox} elevation={0}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant="h1"
                    className={classes.h1}
                    align="center"
                    gutterBottom
                  >
                    CONTACT US
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    paragraph
                    className={classes.subtitle}
                    align="center"
                  >
                    Got a question? Get in touch!
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Background>
      {/* <ContactPage
        streetAddress={streetAddress}
        legalAddress={legalAddress}
        cityStateZip={cityStateZip}
        email={email}
        phone={phone}
      /> */}
      <ContactPageTwo />
      <Divider className={classes.hr} />
      <ContactForm />
      <Divider className={classes.hrTwo} />
      {/* <NewsletterSignup
        marginTop={theme.spacing(2)}
        marginBottom={theme.spacing(12)}
      /> */}
    </Layout>
  )
}

// export const query = graphql`
//   query Contact {
//     site {
//       siteMetadata {
//         streetAddress
//         legalAddress
//         cityStateZip
//         email
//         phone
//       }
//     }
//   }
// `

export default ContactPg
