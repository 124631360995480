import React from "react"
import { Link } from "gatsby"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"

const useStyles = makeStyles(theme => ({
  h2: {
    fontFamily: "Lora, Work Sans, sans-serif",
    fontSize: "1.5rem",
    fontWeight: 500,
  },
  h3: {
    fontFamily: "Work Sans, sans-serif",
    fontSize: "1.0515rem",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.15rem",
    },
  },
  h4: {
    fontFamily: "Work Sans, sans-serif",
    color: theme.palette.secondary.light,
    fontSize: "1.2rem",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.0815rem",
    },
  },
  li: {
    fontFamily: "Work Sans, sans-serif",
    marginBottom: ".25rem",
    fontSize: ".95rem",
  },
}))

let ContactPageTwo = (props) => {
  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"))
  const classes = useStyles()

  return (
    <>
      <Container maxWidth="lg" style={{ paddingTop: "3rem" }}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Typography
              variant="h2"
              align="center"
              className={classes.h2}
              gutterBottom
            >
              We love meeting new people and are excited to get to know you.
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: ".5rem" }}>
            <Typography align="center" variant="h3" className={classes.h3}>
              We have two options for helping you get your questions answered.
            </Typography>
          </Grid>
          <Container maxWidth={matchesSM ? "lg" : "md"} style={{ padding: 20 }}>
            <Grid
              item
              xs={12}
              container
              style={{ paddingTop: matchesSM ? "1rem" : "1.5rem" }}
              spacing={4}
            >
              <Grid item xs={12} md={6}>
                <Typography variant="h4" className={classes.h4}>
                  Free Phone Consultation
                </Typography>
                <ul>
                  <li className={classes.li}>
                    Fill out the contact form below
                  </li>
                  <li className={classes.li}>
                    Upload pictures of your existing water feature or area for
                    your project
                  </li>
                  <li className={classes.li}>
                    We'll call you to discuss your repair or project idea
                  </li>
                  <li className={classes.li}>
                    If an in-person consultation is needed, we can schedule it
                    at that time
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h5" className={classes.h4}>
                  In-Person Consultation (Starting at $250)
                </Typography>
                <ul>
                  <li className={classes.li}>
                    Fill out the contact form below
                  </li>
                  <li className={classes.li}>
                    We'll call to schedule a time for a consultation or repair
                    at your property
                  </li>
                  <li className={classes.li}>
                    {/* See our <Link to="/new-installations/" style={{color: theme.palette.secondary.light,}}>services page</Link>{" "} */}
                    See our services page
                    for more information on what we can help you with
                  </li>
                </ul>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "1rem" }}>
              <Typography variant="body1">
                Please fill out the form below. You can upload pictures to help
                us better understand your needs when we call you.
              </Typography>
            </Grid>
          </Container>
        </Grid>
      </Container>
    </>
  )
}

export default ContactPageTwo
