import React from "react"
import { navigate } from "@reach/router"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import InputBase from "@material-ui/core/InputBase"
import Button from "@material-ui/core/Button"
import Alert from '@material-ui/lab/Alert'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import PlusSymbol from '@material-ui/icons/PlusOne'
import Compressor from 'compressorjs'

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,

    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: theme.palette.secondary.light,
      boxShadow: "0 0 0 0.2rem rgba(218,114,53,.25)",
      // boxShadow: "0 0 0 0.2rem rgba(8,191,234,.25)",
    },
  },
}))(InputBase)

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '3rem',
    paddingTop: '1rem',
    paddingBottom: '2rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "rgba(0,0,0,.03)",
    maxWidth: "50%",
    [theme.breakpoints.only("xl")]: {
      maxWidth: "30%",
    },
    [theme.breakpoints.only("lg")]: {
      maxWidth: "40%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "70%",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  h3: {
    fontFamily: "Work Sans, sans-serif",
    // color: theme.palette.common.muted,
    color: theme.palette.secondary.light,
    fontWeight: 600,
    fontSize: "1.9375rem",
  },
  hr: {
    width: "10em",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderStyle: "solid",
    height: ".135rem",
    // borderColor: theme.palette.common.blue,
    borderColor: theme.palette.secondary.main,
    marginTop: ".5em",
    marginBottom: "2em",
  },
  margin: {
    // marginLeft: 8,
    // marginRight: 8,
    marginBottom: 8,
    marginTop: 19,
    width: "100%",
  },
  btn: {
    marginTop: "1.5625rem",
    fontSize: "1rem",
    marginLeft: 8,
    width: "6.625rem",
  },
  input: {
    display: 'none',
  },
  uploadBtn: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    width: '8rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: '2px',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },

  }
}))

function encode(data) {
  const formData = new FormData()

  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData
}
export default function ContactForm() {
  const classes = useStyles()

  const [state, setState] = React.useState({
    "Request Type": "Design Consultation",
    "How did you hear about us": "Internet search",
  })
  const [imageError, setImageError] = React.useState(null)
  const [imageErrorName, setImageErrorName] = React.useState(null)
  const [imageOne, setImageOne] = React.useState(null)
  const [imageTwo, setImageTwo] = React.useState(null)
  const [imageThree, setImageThree] = React.useState(null)
  const [imageFour, setImageFour] = React.useState(null)
  const [imageFive, setImageFive] = React.useState(null)
  const [addImageButton, setAddImageButton] = React.useState(false)
  const [showImageTwo, setShowImageTwo] = React.useState(false)
  const [showImageThree, setShowImageThree] = React.useState(false)
  const [showImageFour, setShowImageFour] = React.useState(false)
  const [showImageFive, setShowImageFive] = React.useState(false)
  const [addInputCount, setAddInputCount] = React.useState(1)

  const handleAddInputClick = e => {

    if(addInputCount == 1) {
      setAddInputCount(2)
      setShowImageTwo(true)
    } else if (addInputCount == 2) {
      setAddInputCount(3)
      setShowImageThree(true)
    } else if (addInputCount == 3) {
      setAddInputCount(4)
      setShowImageFour(true)
    } else if (addInputCount == 4) {
      setAddInputCount(5)
      setShowImageFive(true)
      setAddImageButton(false)
    }

  }

  const handleUpdate = e => {
    setState({ ...state, [e.target.name]: e.target.value })
    if (e.target.name === "Full Name") {
      // console.log(e.target.value.split(" ")[0])
      localStorage.setItem("fname", e.target.value.split(" ")[0])
    }
  }
  // const handleAttachment = e => {
  //   setState({ ...state, [e.target.name]: e.target.files[0] })
  // }

  const handleAttachment = e => {
    if (e.target.files[0].size > 4194304) {
      setImageError(true)
      setImageErrorName(e.target.files[0].name)
      e.target.value = null
    } else {
      new Compressor(e.target.files[0], {
        quality: 0.2,
        success(result) {
          setState({ ...state, [e.target.name]: result })
          setImageError(null)
          setImageErrorName(null)
        }
      })
      // setState({ ...state, [e.target.name]: e.target.files[0] })
      // setImageError(null)
      // setImageErrorName(null)

      if(e.target.id == 'file-upload-1') {
        setImageOne(e.target.files[0].name)
        setAddImageButton(true)
      } else if (e.target.id == 'file-upload-2') {
        setImageTwo(e.target.files[0].name)
      } else if (e.target.id == 'file-upload-3') {
        setImageThree(e.target.files[0].name)
      } else if (e.target.id == 'file-upload-4') {
        setImageFour(e.target.files[0].name)
      } else if (e.target.id == 'file-upload-5') {
        setImageFive(e.target.files[0].name)
      }
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate("/submission-received/"))
      .catch(error => alert(error))
  }

  return (
    <Paper className={classes.root} elevation={0}>
      <Typography variant="h6" align="center" className={classes.h3}>
        Contact Form
      </Typography>
      <hr className={classes.hr} />
      <form
        name="Contact Form"
        onSubmit={handleSubmit}
        data-netlify="true"
        // HONEYPOT SPAM PROTECTION:
        netlify-honeypot="contact-reason"
        style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}
      >
        {/* BEGIN HONEYPOT */}
        <input type="hidden" name="form-name" value="Contact Form" />
        <TextField
          name="contact-reason"
          hidden
          id="contact-reason"
          label="Contact Reason"
          placeholder="Reason for Contacting"
          style={{ display: "none" }}
        >
          {/* END HONEYPOT */}
        </TextField>
        <TextField
          className={classes.textField}
          required
          id="Full Name"
          name="Full Name"
          label="First and last name"
          placeholder="First and last name"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleUpdate}
        />
        <TextField
          className={classes.textField}
          required
          id="Phone Number"
          name="Phone Number"
          label="Phone Number"
          style={{
            marginBottom: 8,
            marginTop: 19,
          }}
          placeholder="Phone Number"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleUpdate}
        />
        <TextField
          className={classes.textField}
          required
          id="Email Address"
          name="Email Address"
          label="Email Address"
          style={{
            marginBottom: 8,
            marginTop: 19,
          }}
          placeholder="Email Address"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleUpdate}
        />
        <FormControl className={classes.margin}>
          <InputLabel id="Request Type">Request Type</InputLabel>
          <Select
            labelId="Request Type"
            label="Request Type"
            id="Request Type"
            name="Request Type"
            required
            // value="Design Consultation"
            value={state["Request Type"]}
            onChange={handleUpdate}
            input={<BootstrapInput />}
          >
            <MenuItem value={"Design Consultation"}>
              Design Consultation
            </MenuItem>
            <MenuItem value={"Pond Analysis"}>Pond Analysis</MenuItem>
            <MenuItem value={"Maintenance"}>Maintenance</MenuItem>
            <MenuItem value={"Repair"}>Repair</MenuItem>
            <MenuItem value={"General Inquiry"}>General Inquiry</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.margin}>
          <InputLabel id="How did you hear about us">
            How did you hear about us?
          </InputLabel>
          <Select
            labelId="How did you hear about us"
            label="How did you hear about us"
            id="How did you hear about us"
            name="How did you hear about us"
            required
            // value="Internet search"
            value={state["How did you hear about us"]}
            onChange={handleUpdate}
            input={<BootstrapInput />}
          >
            <MenuItem value={"Internet search"}>Internet search</MenuItem>
            <MenuItem value={"Referral"}>Referral</MenuItem>
            <MenuItem value={"Home Show"}>Home Show</MenuItem>
            <MenuItem value={"Other"}>Other</MenuItem>
          </Select>
        </FormControl>
        <TextField
          className={classes.textField}
          required
          id="Message"
          name="Message"
          label="Message"
          onChange={handleUpdate}
          style={{
            marginBottom: 8,
            marginTop: 19,
          }}
          placeholder="Message"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          rows={6}
        />
        <div style={{ padding: 10 }}>
          <Grid
            container
            spacing={2}
            style={{ marginTop: -5 }}
            className={classes.margin}
          >
            <Grid item xs={12}>
              <Typography variant="subtitle1" className={classes.subtitle}>
                Please upload 3-5 pictures with a general description above of
                what your needs are
              </Typography>
            </Grid>
            <Collapse in={imageError}>
              <Grid item xs={12}>
                <Alert severity="error">
                  File exceeds maximum upload file size (4MB):{" "}
                  {imageErrorName && imageErrorName}
                </Alert>
              </Grid>
            </Collapse>
            {/*  */}
            {/* First Image Input: */}
            <Grid item xs={12} sm={9}>
              {/* <input
                type="file"
                name="File Upload 1"
                id="file-upload-1"
                onChange={handleAttachment}
                className={classes.input}
              /> */}
              {/* <label htmlFor="file-upload-1" className={classes.margin}> */}
              <label className={classes.margin}>
                <input
                  type="file"
                  name="File Upload 1"
                  id="file-upload-1"
                  onChange={handleAttachment}
                  className={classes.input}
                />
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.uploadBtn}
                  component="span"
                >
                  {imageOne ? imageOne : "Upload"}
                </Button>
              </label>
            </Grid>
            <Collapse in={addImageButton}>
              <Grid item xs={3}>
                <IconButton
                  color="primary"
                  aria-label="Upload another image"
                  component="span"
                  style={{ color: "#d7dadc" }}
                  onClick={() => handleAddInputClick()}
                >
                  <PlusSymbol />
                </IconButton>
              </Grid>
            </Collapse>

            {/* End First Image Input */}
            {/*  */}
            {/* Begin Second Image Input */}

            <Grid
              item
              xs={12}
              sm={9}
              style={{ display: showImageTwo ? "block" : "none" }}
            >
              <Collapse in={showImageTwo}>
                {/* <input
                  type="file"
                  name="File Upload 2"
                  id="file-upload-2"
                  onChange={handleAttachment}
                  className={classes.input}
                /> */}
                {/* <label htmlFor="file-upload-2" className={classes.margin}> */}
                <label className={classes.margin}>
                  <input
                    type="file"
                    name="File Upload 2"
                    id="file-upload-2"
                    onChange={handleAttachment}
                    className={classes.input}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.uploadBtn}
                    component="span"
                  >
                    {imageTwo ? imageTwo : "Upload"}
                  </Button>
                </label>
              </Collapse>
            </Grid>

            {/* End Second Image Input */}
            {/*  */}
            {/* Begin Third Image Input */}

            <Grid
              item
              xs={12}
              sm={9}
              style={{ display: showImageThree ? "block" : "none" }}
            >
              <Collapse in={showImageThree}>
                {/* <input
                  type="file"
                  name="File Upload 3"
                  id="file-upload-3"
                  onChange={handleAttachment}
                  className={classes.input}
                /> */}
                {/* <label htmlFor="file-upload-3" className={classes.margin}> */}
                <label className={classes.margin}>
                  <input
                    type="file"
                    name="File Upload 3"
                    id="file-upload-3"
                    onChange={handleAttachment}
                    className={classes.input}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.uploadBtn}
                    component="span"
                  >
                    {imageThree ? imageThree : "Upload"}
                  </Button>
                </label>
              </Collapse>
            </Grid>
            {/* End Third Image Input */}
            {/*  */}
            {/* Begin Fourth Image Input */}

            <Grid
              item
              xs={12}
              sm={9}
              style={{ display: showImageFour ? "block" : "none" }}
            >
              <Collapse in={showImageFour}>
                {/* <input
                  type="file"
                  name="File Upload 4"
                  id="file-upload-4"
                  onChange={handleAttachment}
                  className={classes.input}
                /> */}
                {/* <label htmlFor="file-upload-4" className={classes.margin}> */}
                <label className={classes.margin}>
                  <input
                    type="file"
                    name="File Upload 4"
                    id="file-upload-4"
                    onChange={handleAttachment}
                    className={classes.input}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.uploadBtn}
                    component="span"
                  >
                    {imageFour ? imageFour : "Upload"}
                  </Button>
                </label>
              </Collapse>
            </Grid>

            {/* End Fourth Image Input */}
            {/*  */}
            {/* Begin Five Image Input */}

            <Grid
              item
              xs={12}
              sm={9}
              style={{ display: showImageFive ? "block" : "none" }}
            >
              <Collapse in={showImageFive}>
                {/* <input
                  type="file"
                  name="File Upload 5"
                  id="file-upload-5"
                  onChange={handleAttachment}
                  className={classes.input}
                /> */}
                {/* <label htmlFor="file-upload-5" className={classes.margin}> */}
                <label className={classes.margin}>
                  <input
                    type="file"
                    name="File Upload 5"
                    id="file-upload-5"
                    onChange={handleAttachment}
                    className={classes.input}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.uploadBtn}
                    component="span"
                  >
                    {imageFive ? imageFive : "Upload"}
                  </Button>
                </label>
              </Collapse>
            </Grid>

            {/*
            <input
                  type="file"
                  name="File Upload 1"
                  onChange={handleAttachment}
                />
                          <Grid item xs={12} sm={9}>
                <label className={classes.margin}>
                  <input
                    type="file"
                    name="File Upload 2"
                    onChange={handleAttachment}
                  />
                </label>
              </Grid>
            <Grid item xs={12} sm={9}>
              <label className={classes.margin}>
                <input
                  type="file"
                  name="File Upload 3"
                  onChange={handleAttachment}
                />
              </label>
            </Grid>
            <Grid item xs={12} sm={9}>
              <label className={classes.margin}>
                <input
                  type="file"
                  name="File Upload 4"
                  onChange={handleAttachment}
                />
              </label>
            </Grid>
            <Grid item xs={9}>
              <label className={classes.margin}>
                <input
                  type="file"
                  name="File Upload 5"
                  onChange={handleAttachment}
                />
              </label>
            </Grid> */}
          </Grid>
        </div>

        <Button
          variant="contained"
          color="secondary"
          className={classes.btn}
          type="submit"
        >
          send
        </Button>
      </form>
    </Paper>
  )
}
